module.exports = {
  // App Info
  appstore_link: "https://itunes.apple.com/us/app/id1437949461?mt=8", // Enter App Store URL.
  playstore_link:
    "https://play.google.com/store/apps/details?id=com.ultimate531", // Enter Google Play Store URL.
  google_analytics_ID: "UA-109623725-6", // Enter Google Analytics ID or ""
  contact: "/Contact",
  terms_of_use: "/terms-of-use",
  privacy_policy: "/privacy-policy",
  video_or_screenshot: "screenshot", // "screenshot" or "video"
  app_url: "https://keylifts.com", // Domain of your website without path_prefix.
  path_prefix: "/", // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name: "5/3/1 Routine Picker",
  app_price: "Takes the math out of 5/3/1",
  app_description: "Built from the ground up for running 5/3/1",
  app_keywords: ["5/3/1", "Jim Wendler", "workout"],

  // Personal Info
  your_name: "Jimmy Cook",
  your_link: "https://keylifts.com",
  your_city: "",
  email_address: "jimmy@keylifts.com",
  facebook_username: null,
  instagram_username: null,
  twitter_username: null,
  github_username: null,
  youtube_username: null,

  // Features List
  features: [
    {
      title: "100+ 5/3/1 Templates",
      description:
        "Over 100 preloaded templates from Jim Wendler's 5/3/1 books.",
      fontawesome_icon_name: "dumbbell",
    },
    {
      title: "Know what to do everytime you step into the gym",
      description: "Easily plan you next cycle with one button press",
      fontawesome_icon_name: "brain",
    },
    {
      title: "Automatically calculated weights for each set",
      description:
        "KeyLifts takes the math out of the 5/3/1 program so you can focus on getting stronger and hitting new PRs.",
      fontawesome_icon_name: "calculator",
    },
    {
      title: "Plate Calculator",
      description:
        "Stop wasting time and energy figuring out which plates you need for each set.",
      fontawesome_icon_name: "weight-hanging",
    },
    {
      title: "Graphs",
      description:
        "Be confident you are making progress by seeing graphs of your one-rep maxes over time",
      fontawesome_icon_name: "chart-line",
    },
    {
      title: "Custom workouts built for your specific needs",
      description:
        "Customize any 5/3/1 template to track and hit any specific goals you have.",
      fontawesome_icon_name: "star",
    },
  ],
  header_background: "rgba(0, 0, 0, 0.1)",
  topbar_title_color: "#ffffff",
  cover_overlay_color_rgba: "rgba(54, 59, 61, 0.8)",
  device_color: "black", // Set to "black", "blue", "coral", "white", or "yellow"
  body_background_color: "#ffffff",
  primary_text_color: "#000",
  content_width: "1170px",
  font: `"Helvetica Neue", sans-serif`,
  link_color: "#00add0",
  app_title_color: "#ffffff",
  app_price_color: "#ffffff",
  app_description_color: "#ffffff",
  feature_title_color: "#000000",
  feature_text_color: "#666666",
  feature_icons_foreground_color: "#00add0",
  feature_icons_background_color: "#e6e6e6",
  social_icons_foreground_color: "#666666",
  social_icons_background_color: "#e6e6e6",
  footer_text_color: "#666666",
}
